import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import BlogRoll from '../components/BlogRoll';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

import { IndexPageTemplateQuery } from '../../graphql-types';
import { ReactComponent as ArrowRight } from '../svg/icon/icon-arrow-right.svg';
import { Hero } from '../components/design';

interface IndexPageTemplateProps {
  image: any;
  title: string;
  heading: string;
  subheading: string;
  description: string;
  intro: {
    heading?: string;
    description?: string;
    blurbs?: any[];
  };
  mainpitch: {
    title?: string;
    description?: string;
  };
}

export const IndexPageTemplate: React.FC<IndexPageTemplateProps> = ({
  image,
  title,
  heading,
  subheading,
  description,
  intro,
  mainpitch,
}) => {
  return (
    <>
      <Hero title={title} subheading={subheading} image={image} />

      <section className="section bg-gray-100 flex flex-col justify-center">
        <h2>{mainpitch.title}</h2>
        <p>{mainpitch.description}</p>
      </section>

      <section className="section">
        <header className="section-y">
          <h3>{intro.heading}</h3>
          <p>{intro.description}</p>
        </header>
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 col-gap-20">
          {intro.blurbs.map((item) => (
            <article className="section-y" key={item.text}>
              <PreviewCompatibleImage imageInfo={item} />
              <p className="section-y-sm">{item.text}</p>
            </article>
          ))}
        </section>
        <Link to="/products" className="btn-link">
          See all products
          <ArrowRight className="ml-2" />
        </Link>
      </section>

      <section className="section bg-gray-100">
        <header className="section-y-sm">
          <h3>Latest Stories</h3>
        </header>
        <BlogRoll />
      </section>

      <section className="section">
        <h3>We update our blog regularly</h3>
        <p className="mb-8">
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
          nulla pariatur.
        </p>
        <Link className="btn" to="/blog">
          Read more
        </Link>
      </section>
    </>
  );
};

interface IndexPageProps {
  data: IndexPageTemplateQuery;
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { image, title, subheading, mainpitch, intro, heading, description },
    },
  } = data;

  return (
    <Layout>
      <IndexPageTemplate
        image={image}
        title={title}
        heading={heading}
        description={description}
        subheading={subheading}
        mainpitch={mainpitch}
        intro={intro}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxHeight: 480, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 910, maxHeight: 910) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
